import tinycolor from '@ctrl/tinycolor'

/**
 * Represents an RGB color
 */
export default class Color {
  readonly r: number
  readonly g: number
  readonly b: number

  constructor(r: number, g: number, b: number) {
    this.r = Math.min(255, Math.max(0, r))
    this.g = Math.min(255, Math.max(0, g))
    this.b = Math.min(255, Math.max(0, b))
  }

  toString(): string {
    return this.toHex()
  }

  toRGBA(): string {
    return `rgba(${this.r},${this.g},${this.b},1)`
  }

  toHex(): string {
    return tinycolor(this.toRGBA()).toHex()
  }

  /**
   * Converts the color to its HSL representation
   */
  toHSL(): { h: number; s: number; l: number } {
    const r = this.r / 255
    const g = this.g / 255
    const b = this.b / 255

    let h = 0
    let s = 0
    let l = 0

    const min = Math.min(r, g, b)
    const max = Math.max(r, g, b)

    if (max === min) {
      h = 0
    } else if (max === r) {
      h = 60 * (0 + (g - b) / (max - min))
    } else if (max === g) {
      h = 60 * (2 + (b - r) / (max - min))
    } else if (max === b) {
      h = 60 * (4 + (r - g) / (max - min))
    }

    if (h < 0) {
      h += 360
    }

    l = (min + max) / 2

    if (max === 0 || min === 1) {
      s = 0
    } else {
      s = (max - l) / Math.min(l, 1 - l)
    }

    return { h, s, l }
  }

  /**
   * Generate a darker color from the current color by the specified amount from 0 to 1.
   */
  darken(amount: number): Color {
    return new Color(this.r * (1 - amount), this.g * (1 - amount), this.b * (1 - amount))
  }

  /**
   * Generate a lighter color from the current color by the specified amount from 0 to 1.
   */
  lighten(amount: number): Color {
    return new Color(this.r * (1 + amount), this.g * (1 + amount), this.b * (1 + amount))
  }

  /**
   * Generate a new color by inverting the current one
   */
  invert(): Color {
    return new Color(255 - this.r, 255 - this.g, 255 - this.b)
  }

  /**
   * Generate a new color by inverting the current one
   */
  complimentary(angle = 180): Color {
    const { h, s, l } = this.toHSL()
    return Color.fromHSL((h + angle) % 360, s, l)
  }

  /**
   * Generate a distint color for a range f color
   */
  static distictFromRange(index: number, total: number, seed = 0): Color {
    return Color.fromHSL((((index % total) / total) * 360 + seed) % 360, 1, 0.5)
  }

  /**
   * Create color from hex 8 representation
   */
  static fromHex(hex: string): Color {
    const color = tinycolor(hex)
    return new Color(color.r, color.g, color.b)
  }

  /**
   * Create a color from HLS specification
   */
  static fromHSL(h: number, s: number, l: number): Color {
    const c = (1 - Math.abs(2 * l - 1)) * s
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
    const m = l - c / 2
    let r = 0
    let g = 0
    let b = 0

    if (0 <= h && h < 60) {
      r = c
      g = x
      b = 0
    } else if (60 <= h && h < 120) {
      r = x
      g = c
      b = 0
    } else if (120 <= h && h < 180) {
      r = 0
      g = c
      b = x
    } else if (180 <= h && h < 240) {
      r = 0
      g = x
      b = c
    } else if (240 <= h && h < 300) {
      r = x
      g = 0
      b = c
    } else if (300 <= h && h < 360) {
      r = c
      g = 0
      b = x
    }
    r = Math.round((r + m) * 255)
    g = Math.round((g + m) * 255)
    b = Math.round((b + m) * 255)
    return new Color(r, g, b)
  }

  static BLACK = new Color(0, 0, 0)
  static RED = new Color(255, 0, 0)
  static GREEN = new Color(0, 255, 0)
  static BLUE = new Color(0, 0, 255)
}
