import Playground from 'Playground/Playground'
import NotFoundProject from 'Projects/NotFound/NotFoundProject'
import Projects from 'Projects/Projects'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route index element={<Playground project={Projects.ALL[0]} />} />
          {Projects.ALL.map((project) => {
            return <Route key={project.id} path={project.id} element={<Playground project={project} />} />
          })}
          <Route path='*' element={<Playground project={new NotFoundProject()} />} />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
