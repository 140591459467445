import { Box, SxProps, Theme } from '@mui/material'
import GraphicsObject from 'Graphics/GraphicsObject'
import Renderer from 'Graphics/Renderer'
import useDebounce from 'Playground/Hooks/useDebounce'
import { useEffect, useRef } from 'react'

export interface Props {
  sx?: SxProps<Theme>
  renderer: Renderer
  animationLengthMillis?: number
  onRefresh: (width: number, height: number, animationPosition?: number) => GraphicsObject
}

/**
 * Canvas that adapts to screen size and supports rendering via Renderer
 */
const RenderingCanvas = ({ sx, renderer, animationLengthMillis, onRefresh }: Props) => {
  const container = useRef<HTMLDivElement>(null)

  // Render elements (and resize the canvas if needed)
  const render = () => {
    const width = container.current?.offsetWidth || 0
    const height = container.current?.offsetHeight || 0
    if (width == 0 || height == 0) {
      return
    }
    // const startTime = Date.now()
    const animationPosition = animationLengthMillis
      ? ((Date.now() - animationStartTime) % animationLengthMillis) / animationLengthMillis
      : undefined
    renderer.render(onRefresh(width, height, animationPosition))
    // console.log(`Render: w=${width}, h=${height} time=${Date.now() - startTime}ms`)
  }

  // Debounced version for resizing
  const renderDebounced = useDebounce(render, 50)

  // Initialize the renderer
  useEffect(() => {
    if (container.current) {
      renderer.init(container.current)
      render()
      window.addEventListener('resize', renderDebounced, false)
    }

    return () => {
      renderer.dispose()
      window.removeEventListener('resize', renderDebounced)
    }
  }, [renderer])

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const animationRequestRef = useRef(0)
  const animationStartTime = Date.now()
  const animate = () => {
    render()
    if (animationLengthMillis) {
      animationRequestRef.current = requestAnimationFrame(animate)
    }
  }

  useEffect(() => {
    if (animationLengthMillis) {
      animationRequestRef.current = requestAnimationFrame(animate)
    } else {
      render()
    }
    return () => {
      cancelAnimationFrame(animationRequestRef.current)
    }
  })

  return <Box ref={container} sx={sx}></Box>
}

export default RenderingCanvas
