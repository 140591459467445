import Color from 'Graphics/Color'
import GraphicsObject from 'Graphics/GraphicsObject'
import GraphicsObjectGroup from 'Graphics/GraphicsObjectGroup'
import Path from 'Graphics/Path'
import Point from 'Graphics/Point'
import Rect from 'Graphics/Rect'
import { ColorValue, Config, ConfigValues } from 'Playground/Config'
import Project from 'Playground/Project'

type NotFoundProjectConfig = {
  backgroundColor: ColorValue
  shapeColor: ColorValue
}

class NotFoundProject implements Project<ConfigValues, NotFoundProjectConfig> {
  id = 'not-found'
  name = 'Project Not Found'
  defaultConfig = new Config({})
  defaultDebugConfig = new Config<NotFoundProjectConfig>({
    backgroundColor: new ColorValue(new Color(146, 205, 0)),
    shapeColor: new ColorValue(new Color(255, 16, 240)),
  })
  controlsEnabled = false

  renderObject(
    width: number,
    height: number,
    config: Config<ConfigValues>,
    debugConfig: Config<NotFoundProjectConfig>,
  ): GraphicsObject {
    const group = new GraphicsObjectGroup()
    group.addChild(
      new Rect(0, 0, width, height).setFillRadialGradient(
        debugConfig.values.backgroundColor.val,
        debugConfig.values.backgroundColor.val.complimentary(90),
        true,
      ),
    )
    group.addChild(
      new Rect(width / 2 - 80, height / 2 - 80, 160, 160).setFillRadialGradient(
        debugConfig.values.shapeColor.val,
        debugConfig.values.shapeColor.val.complimentary(160),
        true,
      ),
    )
    group.addChild(
      new Rect(width / 2 - 60, height / 2 - 60, 40, 40).setFillRadialGradient(
        debugConfig.values.backgroundColor.val,
        debugConfig.values.backgroundColor.val.complimentary(90),
        true,
      ),
    )
    group.addChild(
      new Rect(width / 2 + 20, height / 2 - 60, 40, 40).setFillRadialGradient(
        debugConfig.values.backgroundColor.val,
        debugConfig.values.backgroundColor.val.complimentary(90),
        true,
      ),
    )
    group.addChild(
      new Path([
        new Point(width / 2 - 60, height / 2 + 60),
        new Point(width / 2 - 20, height / 2 + 20),
        new Point(width / 2 + 20, height / 2 + 20),
        new Point(width / 2 + 60, height / 2 + 60),
        new Point(width / 2 + 20, height / 2 + 30),
        new Point(width / 2 - 20, height / 2 + 30),
      ]).setFillRadialGradient(
        debugConfig.values.backgroundColor.val,
        debugConfig.values.backgroundColor.val.complimentary(90),
        true,
      ),
    )
    return group
  }
}

export default NotFoundProject
