import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import Color from 'Graphics/Color'
import { MuiColorInput } from 'mui-color-input'
import { ArrayValue, BooleanValue, ColorValue, Config, ConfigValues, EnumValue, NumberValue } from 'Playground/Config'
import { useState } from 'react'

export interface ControlPanelProps<T extends ConfigValues> {
  title: string
  config: Config<T>
  defaultConfig: Config<T>
  onConfigChange: (config: Config<T>) => void
  disabled?: boolean
}

/**
 * Component for configuring projects
 */
export default function ControlPanel<T extends ConfigValues>({
  title,
  config,
  defaultConfig,
  onConfigChange,
  disabled,
}: ControlPanelProps<T>) {
  // Display version of the config, which can be different from the actual version of config
  // in case user enters invalid value, but we don't want to notify upstream of the change
  const [displayConfig, setDisplayConfig] = useState(config)

  // Revert back any invalid display configs
  const onValidate = () => {
    if (!displayConfig.isValid()) {
      setDisplayConfig(config)
    }
  }

  // Validate the config and trigger onChange event only if the config is valid
  const onChange = (newValues: Partial<T>) => {
    const newConfig = config.edit(newValues)
    setDisplayConfig(newConfig)
    if (newConfig.isValid()) {
      onConfigChange(newConfig)
    }
  }

  // Convert config keys to user readable labels
  const keyToReadableLabel = (key: string): string => {
    const result: string[] = []
    let word = ''
    for (const char of key) {
      if (char.toUpperCase() === char) {
        result.push(word)
        word = ''
      }
      word += word.length == 0 ? char.toUpperCase() : char
    }
    result.push(word)
    return result.filter(Boolean).join(' ')
  }

  return (
    <Box>
      <ListSubheader component='div'>{title}</ListSubheader>
      <Box sx={{ pl: 1, pr: 1, pt: 2, pb: 1 }}>
        <Grid container spacing={2} direction={'column'}>
          {displayConfig.keys.map((configKey) => {
            const configVal = displayConfig.values[configKey]
            const defaultVal = defaultConfig.values[configKey]
            const label = keyToReadableLabel(configKey as string)
            const gridSize = 1
            const controlSize = 'small'
            if (configVal instanceof ArrayValue) {
              return (
                <Grid item xs={gridSize} key={configKey as string}>
                  <TextField
                    value={configVal.index}
                    disabled={disabled}
                    onChange={(event) =>
                      onChange({ ...config.values, [configKey]: configVal.editWithIndex(parseInt(event.target.value)) })
                    }
                    onBlur={() => onValidate()}
                    select // tell TextField to render select
                    label={label}
                    fullWidth
                    size={controlSize}
                  >
                    {configVal.values.map((spec) => {
                      const selectLabel = configVal.labelOf(spec)
                      const selectValue = configVal.indexOf(spec)
                      return (
                        <MenuItem key={selectLabel} value={selectValue}>
                          {selectLabel}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
              )
            } else if (configVal instanceof EnumValue) {
              return (
                <Grid item xs={gridSize} key={configKey as string}>
                  <TextField
                    value={configVal.val}
                    disabled={disabled}
                    onChange={(event) =>
                      onChange({ ...config.values, [configKey]: configVal.edit(parseInt(event.target.value)) })
                    }
                    onBlur={() => onValidate()}
                    select // tell TextField to render select
                    label={label}
                    fullWidth
                    size={controlSize}
                  >
                    {configVal.values.map((enumValue, index) => {
                      return (
                        <MenuItem key={enumValue} value={index}>
                          {enumValue}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
              )
            } else if (configVal instanceof NumberValue) {
              // Numbers are always displayed as percentages of the valid range
              return (
                <Grid item xs={gridSize} key={configKey as string}>
                  <TextField
                    value={isNaN(configVal.val) ? '' : Math.floor(configVal.position * 100)}
                    disabled={disabled}
                    color={!configVal.isValid() ? 'error' : undefined}
                    label={label}
                    fullWidth
                    type='number'
                    size={controlSize}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      min: 0,
                      max: 100,
                      step: Math.ceil(100 / (configVal.max - configVal.min)),
                    }}
                    onChange={(event) =>
                      onChange({
                        ...config.values,
                        [configKey]: configVal.editPosition(parseInt(event.target.value) / 100),
                      })
                    }
                    onBlur={() => onValidate()}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                  />
                </Grid>
              )
            } else if (configVal instanceof ColorValue && defaultVal instanceof ColorValue) {
              return (
                <Grid item xs={gridSize} key={configKey as string}>
                  <MuiColorInput
                    value={configVal.val.toHex()}
                    disabled={disabled}
                    fallbackValue={defaultVal.val.toHex()}
                    format='hex'
                    label={label}
                    size={controlSize}
                    fullWidth
                    onChange={(value) =>
                      onChange({ ...config.values, [configKey]: configVal.edit(Color.fromHex(value)) })
                    }
                  />
                </Grid>
              )
            } else if (configVal instanceof BooleanValue) {
              return (
                <Grid item xs={gridSize} key={configKey as string}>
                  <FormGroup>
                    <FormControlLabel
                      label={label}
                      control={
                        <Checkbox
                          checked={configVal.val}
                          disabled={disabled}
                          size={controlSize}
                          onChange={() => onChange({ ...config.values, [configKey]: configVal.flip() })}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              )
            } else {
              return ''
            }
          })}
        </Grid>
      </Box>
    </Box>
  )
}
