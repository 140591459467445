/** Specifies an immutable 2D affine transform.
The affine transform of a point `(x, y)` is given by:
    [  m11  m21  dx  ] [ x ]   [ m11 x + m21 y + dx ]
    [  m12  m22  dy  ] [ y ] = [ m12 x + m22 y + dy ]
    [   0    0    1  ] [ 1 ]   [          1         ]
Rotation clockwise by theta radians:
    [ cos(theta)  -sin(theta)   0 ]
    [ sin(theta)   cos(theta)   0 ]
    [     0            0        1 ]
Scale:
    [  sx   0    0  ]
    [  0   sy    0  ]
    [  0    0    1  ]
Translate:
    [  1    0   dx  ]
    [  0    1   dy  ]
    [  0    0    1  ]
*/
export default class AffineTransform {
  readonly m11: number
  readonly m12: number
  readonly m21: number
  readonly m22: number
  readonly dx: number
  readonly dy: number

  constructor(m11: number, m12: number, m21: number, m22: number, dx: number, dy: number) {
    this.m11 = m11
    this.m12 = m12
    this.m21 = m21
    this.m22 = m22
    this.dx = dx
    this.dy = dy
  }

  /** Right-multiply this AffineTransform by the given AffineTransform. This has the
  effect that the given AffineTransform will be applied to the input vector before the
  current AffineTransform.
  Concatenating a transform A to transform B is equivalent to matrix multiplication of
  the two transforms. Note that order matters: matrices are applied in right to left order
  when transforming a vector
      A B vector = A * (B * vector)
  We can think of the B matrix being applied first, then the A matrix.  This method
  returns the product with the input `at` matrix on the right
      this * at
  The mathematics is as follows: this matrix is on the left, the other `at` matrix is on
  the right:
      [  m11  m21  dx  ]  [  a11  a21  ax  ]
      [  m12  m22  dy  ]  [  a12  a22  ay  ]
      [   0    0    1  ]  [   0    0   1   ]
  Result is:
      [ (m11*a11 + m21*a12)  (m11*a21 + m21*a22)  (m11*ax + m21*ay + dx) ]
      [ (m12*a11 + m22*a12)  (m12*a21 + m22*a22)  (m12*ax + m22*ay + dy) ]
      [          0                    0                   1              ]
  */
  concatenate(at: AffineTransform): AffineTransform {
    const m11 = this.m11 * at.m11 + this.m21 * at.m12
    const m12 = this.m12 * at.m11 + this.m22 * at.m12
    const m21 = this.m11 * at.m21 + this.m21 * at.m22
    const m22 = this.m12 * at.m21 + this.m22 * at.m22
    const dx = this.m11 * at.dx + this.m21 * at.dy + this.dx
    const dy = this.m12 * at.dx + this.m22 * at.dy + this.dy
    return new AffineTransform(m11, m12, m21, m22, dx, dy)
  }

  /** Concatenates a rotation transformation to this AffineTransform.
  The mathematics is as follows: this matrix is on the left, the rotation matrix is on
  the right, and the angle is represented by `t`
      [  m11  m21  dx  ]  [ cos(t)  -sin(t)   0 ]
      [  m12  m22  dy  ]  [ sin(t)   cos(t)   0 ]
      [   0    0    1  ]  [  0        0       1 ]
  Result is:
      [  (m11*cos(t) + m21*sin(t))  (-m11*sin(t) + m21*cos(t))  0  ]
      [  (m12*cos(t) + m22*sin(t))  (-m12*sin(t) + m22*cos(t))  0  ]
      [              0                          0               1  ]
  */
  rotate(angle: number): AffineTransform {
    const c = Math.cos(angle)
    const s = Math.sin(angle)
    const m11 = c * this.m11 + s * this.m21
    const m12 = c * this.m12 + s * this.m22
    const m21 = -s * this.m11 + c * this.m21
    const m22 = -s * this.m12 + c * this.m22
    return new AffineTransform(m11, m12, m21, m22, this.dx, this.dy)
  }

  /**  Concatenates a scaling transformation to this AffineTransform.
  The mathematics is as follows: this matrix is on the left, the scaling matrix is on
  the right:
      [  m11  m21  dx  ]  [  x   0   0  ]
      [  m12  m22  dy  ]  [  0   y   0  ]
      [   0    0    1  ]  [  0   0   1  ]
  Result is:
      [  m11*x  m21*y  dx  ]
      [  m12*x  m22*y  dy  ]
      [   0      0      1  ]
  */
  scale(x: number, y: number): AffineTransform {
    const m11 = this.m11 * x
    const m12 = this.m12 * x
    const m21 = this.m21 * y
    const m22 = this.m22 * y
    return new AffineTransform(m11, m12, m21, m22, this.dx, this.dy)
  }

  /** Concatenates a translation to this AffineTransform.
   * The mathematics is as follows: this matrix is on the left, the scaling matrix is on
   * the right:
   *     [  m11  m21  dx  ]  [  1    0   x  ]
   *     [  m12  m22  dy  ]  [  0    1   y  ]
   *     [   0    0    1  ]  [  0    0   1  ]
   * Result is:
   *     [ m11  m21  (m11*x + m21*y + dx) ]
   *     [ m12  m22  (m12*x + m22*y + dy) ]
   *     [ 0    0            1            ]
   * @param {!GenericVector|number} x the x coordinate or vector
   * @param {number=} y the y coordinate
   * @return {!AffineTransform} a new AffineTransform equal to
   *     this AffineTransform  translated by the given amount
   */
  translate(x: number, y: number): AffineTransform {
    const dx = this.dx + this.m11 * x + this.m21 * y
    const dy = this.dy + this.m12 * x + this.m22 * y
    return new AffineTransform(this.m11, this.m12, this.m21, this.m22, dx, dy)
  }

  /**
   * Compute an inverse transform
   */
  invert(): AffineTransform {
    const d = 1 / (this.m11 * this.m22 - this.m12 * this.m21)
    return new AffineTransform(
      this.m22 * d,
      -this.m12 * d,
      -this.m21 * d,
      this.m11 * d,
      d * (this.m21 * this.dy - this.m22 * this.dx),
      d * (this.m12 * this.dx - this.m11 * this.dy),
    )
  }
}

/**
 * Represents an identity transform
 */
export const IDENTITY = new AffineTransform(1, 0, 0, 1, 0, 0)
