import AffineTransform from './AffineTransform'
import Color from './Color'
import GraphicsObject from './GraphicsObject'
import Point from './Point'

/**
 * Base class for all fill styles
 */
export interface FillStyle {
  color: Color
}

/**
 * Fill with solid color
 */
export class SolidColorFill {
  readonly color: Color
  constructor(color: Color) {
    this.color = color
  }
}

/**
 * Fill with a radial gradient
 */
export class RadientGradientFill {
  readonly color: Color
  readonly endColor: Color
  readonly relativeToScreen: boolean
  constructor(color: Color, endColor: Color, relativeToScreen: boolean) {
    this.color = color
    this.endColor = endColor
    this.relativeToScreen = relativeToScreen
  }
}

/**
 * Represents a closed path in 2D space, which is the most basic rendering primitive
 */
export default class Path extends GraphicsObject {
  /**
   * The points in the path
   */
  readonly points: Point[]
  /**
   * The fill color of the path
   */
  fillStyle?: FillStyle
  /**
   * Stroke width
   */
  strokeWidth?: number
  /**
   * Stroke color
   */
  strokeColor?: Color

  constructor(points: Point[] = []) {
    super()
    this.points = points
  }

  /**
   * Helper for adding points in Builder fashion
   */
  addPoint(point: Point): Path {
    this.points.push(point)
    return this
  }

  /**
   * Helper for setting fill color in Builder fashion.
   */
  setFillStyle(fillStyle: FillStyle): Path {
    this.fillStyle = fillStyle
    return this
  }

  /**
   * Helper for setting fill color in Builder fashion.
   */
  setFillColor(fillColor: Color): Path {
    this.fillStyle = new SolidColorFill(fillColor)
    return this
  }

  /**
   * Helper for setting fill color in Builder fashion.
   */
  setFillRadialGradient(startColor: Color, endColor: Color, relativeToScreen = false): Path {
    this.fillStyle = new RadientGradientFill(startColor, endColor, relativeToScreen)
    return this
  }

  /**
   * Helper for setting stroke in Builder fashion.
   */
  setStroke(strokeWidth: number, strokeColor: Color): Path {
    this.strokeWidth = strokeWidth
    this.strokeColor = strokeColor
    return this
  }

  /**
   * Since path is a primitive, it doesn't have any components
   */
  getComponents(): GraphicsObject[] {
    return []
  }

  /**
   * Compute center point
   */
  getCenterPoint(): Point {
    const sum = this.points.reduce((center, p) => new Point(center.x + p.x, center.y + p.y), new Point(0, 0))
    return new Point(sum.x / this.points.length, sum.y / this.points.length)
  }

  /**
   * Compute the coordinate of the top-left boundary point
   */
  getMinPoint(): Point {
    return this.points.reduce(
      (min, p) => new Point(Math.min(min.x, p.x), Math.min(min.y, p.y)),
      new Point(Number.MAX_VALUE, Number.MAX_VALUE),
    )
  }

  /**
   * Compute the coordinate of the bottom-right boundary point
   */
  getMaxPoint(): Point {
    return this.points.reduce((max, p) => new Point(Math.max(max.x, p.x), Math.max(max.y, p.y)), new Point(0, 0))
  }

  /**
   * Create a copy of the path & apply an additional transform (optional)
   */
  copy(transform?: AffineTransform): Path {
    const copy = new Path(this.points)
    copy.setTransform(transform ? this.transform.concatenate(transform) : this.transform)
    return copy
  }

  /**
   * Returns string representation
   */
  toString() {
    return `Path(${this.points.map((p) => `[${p.x},${p.y}]`).join(' ')})`
  }
}
