import GraphicsObject from 'Graphics/GraphicsObject'
import Path from 'Graphics/Path'
import AffineTransform, { IDENTITY } from './AffineTransform'
import GraphicsObjectGroup from './GraphicsObjectGroup'
import Point from './Point'

export default abstract class Renderer {
  /**
   * Container for holding the canvases
   */
  private container?: HTMLDivElement

  /**
   * Last render width
   */
  private width = 0

  /**
   * Last render height
   */
  private height = 0

  /**
   * Represents the rendering state stack
   */
  private stateStack: RenderingState[] = []

  /**
   * Returns the current rendering state
   */
  protected get state(): RenderingState {
    if (this.stateStack.length == 0) {
      throw new Error('Invalid rendering state')
    }
    return this.stateStack[this.stateStack.length - 1]
  }

  /**
   * Returns whether or not the canvas is already initialized
   */
  get isInitialized(): boolean {
    return this.container != undefined
  }

  /**
   * Initialize the canvas using a new container
   */
  init(container: HTMLDivElement) {
    if (this.container) {
      throw Error('Already initialized')
    }
    this.container = container
    this.width = container.offsetWidth
    this.height = container.offsetHeight
    this.stateStack.push({
      transform: IDENTITY,
      transformInverse: IDENTITY,
      screenGradientCenter: new Point(this.width / 2, this.height / 2),
      screenGradientRadius: Math.max(this.width / 2, this.height / 2),
    })
  }

  /**
   * Dispose the renderer
   */
  dispose() {
    if (!this.container) {
      throw Error('Not initialized')
    }
    this.container = undefined
    this.stateStack = []
  }

  /**
   * Render provided elements on the canvas with the transform.
   */
  render(element: GraphicsObject) {
    if (!this.container) {
      throw Error('Not initialized')
    }
    if (this.width != this.container.offsetWidth || this.height != this.container.offsetHeight) {
      this.resize(this.container.offsetWidth, this.container.offsetHeight)
    }
    this.startGroup(element.transform)
    if (element instanceof Path) {
      this.renderPath(element)
    } else if (element instanceof GraphicsObjectGroup) {
      element.children.forEach((child) => {
        this.render(child)
      })
    } else {
      throw Error(`Invalid element: ${element}`)
    }
    this.endGroup()
  }

  /**
   * Start a rendering group with a new transformation
   */
  startGroup(groupTransform: AffineTransform) {
    const groupTransformInverse = groupTransform.invert()
    this.stateStack.push({
      transform: this.state.transform.concatenate(groupTransform),
      transformInverse: groupTransformInverse.concatenate(this.state.transformInverse),
      screenGradientCenter: this.state.screenGradientCenter.transform(groupTransformInverse),
      screenGradientRadius:
        this.state.screenGradientRadius / Math.sqrt(Math.pow(groupTransform.m11, 2) + Math.pow(groupTransform.m12, 2)),
    })
  }

  /**
   * Change the size of the rendering area
   */
  resize(width: number, height: number) {
    if (!this.container) {
      throw Error('Not initialized')
    }
    this.width = width
    this.height = height
  }

  /**
   * Finish the rendering group
   */
  endGroup() {
    this.stateStack.pop()
  }

  /**
   * Render a basic path
   */
  abstract renderPath(path: Path): void

  /**
   * Clear the rendering area
   */
  abstract clear(): void
}

/**
 * Rendering state at each level
 */
interface RenderingState {
  readonly transform: AffineTransform
  readonly transformInverse: AffineTransform
  readonly screenGradientCenter: Point
  readonly screenGradientRadius: number
}
