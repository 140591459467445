import Color from 'Graphics/Color'
import GraphicsObject from 'Graphics/GraphicsObject'
import Point from 'Graphics/Point'
import { animateValue, ArrayValue, BooleanValue, ColorValue, Config, EnumValue, NumberValue } from 'Playground/Config'
import Project from 'Playground/Project'
import { TILING_SPECS } from 'Projects/StarPatterns/Spec/TilingSpecs'
import { StarPattern, StarPatternConfig, StarPatternDebugConfig, StarPatternStyle } from './StarPattern'

class StarPatternsProject implements Project<StarPatternConfig, StarPatternDebugConfig> {
  id = 'stars'

  name = 'Star Patterns'

  defaultConfig = new Config<StarPatternConfig>({
    // User-controllable
    spec: new ArrayValue(
      TILING_SPECS[0],
      TILING_SPECS,
      (spec) => spec.specIndex,
      (spec) => spec.specName,
      {
        random: () => TILING_SPECS[Math.floor(TILING_SPECS.length * Math.random())],
        // animate: (val, pos) => {
        //   return TILING_SPECS[Math.floor(animateValue(0, 0, TILING_SPECS.length, pos, 0.2))]
        // },
      },
    ),
    rotation: new NumberValue(0, 0, 360, {
      random: () => Math.floor(360 * Math.random()),
      animate: (val, pos) => animateValue(0, val, 360, pos),
    }),
    backgroundColor: new ColorValue(new Color(146, 205, 0), {
      random: (seed) => Color.distictFromRange(Math.floor(5 * Math.random()), 30, seed * 360),
      animate: (val, pos) => {
        const { h, s, l } = val.toHSL()
        let newH = h - animateValue(0, 0, 90, pos, 20 /* speed */, true /* alternate */)
        if (newH < 0) {
          newH += 360
        }
        return Color.fromHSL(newH, s, l)
      },
    }),
    zoom: new NumberValue(50, 100, 150),
    starColor: new ColorValue(new Color(255, 16, 240), {
      random: (seed) => Color.distictFromRange(10 + Math.floor(5 * Math.random()), 30, seed * 360),
      animate: (val, pos) => {
        const { h, s, l } = val.toHSL()
        let newH = h - animateValue(0, 0, 90, pos, 20 /* speed */, true /* alternate */)
        if (newH < 0) {
          newH += 360
        }
        return Color.fromHSL(newH, s, l)
      },
    }),
    skew: new NumberValue(0, 50, 100, {
      random: () => 5 + Math.floor(90 * Math.random()),
      animate: (val, pos) => animateValue(1, val, 100, pos, 20 /* speed */, true /* alternate */),
    }),
    ribbonColor: new ColorValue(new Color(255, 207, 121), {
      random: (seed) => Color.distictFromRange(20 + Math.floor(5 * Math.random()), 30, seed * 360),
      animate: (val, pos) => {
        const { h, s, l } = val.toHSL()
        let newH = h - animateValue(0, 0, 90, pos, 20 /* speed */, true /* alternate */)
        if (newH < 0) {
          newH += 360
        }
        return Color.fromHSL(newH, s, l)
      },
    }),
    ribbonScale: new NumberValue(20, 30, 70, {
      random: () => 30 + Math.floor(40 * Math.random()),
      animate: (val, pos) => animateValue(20, val, 70, pos, 35 /* speed */, true /* alternate */),
    }),
    strokeColor: new ColorValue(new Color(0, 0, 0)),
    strokeScale: new NumberValue(20, 30, 70),
  })

  defaultDebugConfig = new Config<StarPatternDebugConfig>({
    style: new EnumValue<StarPatternStyle>(
      StarPatternStyle.TOXIC,
      Object.keys(StarPatternStyle).filter((key) => isNaN(Number(key))),
    ),
    density: new NumberValue(5, 10, 15),
    vignetteIntensity: new NumberValue(5, 20, 100),
    toxicIntensity: new NumberValue(45, 90, 180),
    noRepeat: new BooleanValue(false),
    noRibbons: new BooleanValue(false),
    showTiles: new BooleanValue(false),
  })

  controlsEnabled = true

  renderObject(
    width: number,
    height: number,
    config: Config<StarPatternConfig>,
    debugConfig: Config<StarPatternDebugConfig>,
  ): GraphicsObject {
    return new StarPattern(new Point(width / 2, height / 2), width, height, config, debugConfig)
  }
}

export default StarPatternsProject
