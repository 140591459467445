import AffineTransform, { IDENTITY } from './AffineTransform'

/**
 * Base class for all rendering objects
 */
export default abstract class GraphicsObject {
  /**
   * Transform the object when rendering
   */
  transform: AffineTransform = IDENTITY

  /**
   * Helper for setting transform in Builder fashion
   */
  setTransform(transform: AffineTransform): GraphicsObject {
    this.transform = transform
    return this
  }
}
