import Point from './Point'

/**
 * Find a determinant for 2x2 matrix defined by 2 points
 */
function findDeterminant(a: Point, b: Point): number {
  return a.x * b.y - a.y * b.x
}

/**
 * Find an intersection between two lines A & B defined by 2 points each.
 * https://stackoverflow.com/questions/20677795/how-do-i-compute-the-intersection-point-of-two-lines
 */
function findIntersectionBetweenTwoLines(a1: Point, a2: Point, b1: Point, b2: Point): Point {
  const xdiff = new Point(a1.x - a2.x, b1.x - b2.x)
  const ydiff = new Point(a1.y - a2.y, b1.y - b2.y)

  const div = findDeterminant(xdiff, ydiff)
  if (div == 0) {
    // Lines don't intersect
    throw new Error("Lines don't intersect")
  }

  const d = new Point(findDeterminant(a1, a2), findDeterminant(b1, b2))
  return new Point(findDeterminant(d, xdiff) / div, findDeterminant(d, ydiff) / div)
}

/**
 * Given a point V, an angle and two contact points P1 and P2, find the location of a 4th point such that all 4 points
 * form a convex polygon with the given angle at the contant points.
 */
function completePolygon(v: Point, p1: Point, p2: Point, contactAngle: number): Point {
  // Find line direction points by rotate the point V by the given angle using the contact points
  const angleDirection = v.angleDirection(p1, p2)
  const d1 = v.rotate(angleDirection < 0 ? contactAngle : -contactAngle, p1)
  const d2 = v.rotate(angleDirection < 0 ? -contactAngle : contactAngle, p2)

  try {
    const i = findIntersectionBetweenTwoLines(p1, d1, p2, d2)
    if (p2.angle(i, v) > Math.PI / 2) {
      // Out of bounds for p2
      return p2
    } else if (p1.angle(i, v) > Math.PI / 2) {
      // Out of bounds for p1
      return p1
    } else {
      return i
    }
  } catch {
    // Snap to triangle if there is no intersection
    return p1.shift(p2, 0.5)
  }
}

export default { findDeterminant, findIntersectionBetweenTwoLines, completePolygon }
