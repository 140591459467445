import Project from 'Playground/Project'
import StarPatternsProject from './StarPatterns/StarPatternsProject'

/**
 * Add new projects here
 */
const ALL: Project<any, any>[] = [
  // Islamic Star Patterns
  new StarPatternsProject(),
]

export default { ALL }
