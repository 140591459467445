import AffineTransform, { IDENTITY } from './AffineTransform'

/**
 * Represents a point in 2D space
 */
export default class Point {
  readonly x: number
  readonly y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  toString(): string {
    return `(${this.x},${this.y}))`
  }

  /**
   * Return distance to another point
   */
  distanceTo(p: Point): number {
    return Math.sqrt((this.y - p.y) * (this.y - p.y) + (this.x - p.x) * (this.x - p.x))
  }

  /**
   * Returns a new point by shifting the current point in the direction of another point by ratio of the distance between the points
   */
  shift(direction: Point, ratio: number): Point {
    return new Point(this.x * (1 - ratio) + direction.x * ratio, this.y * (1 - ratio) + direction.y * ratio)
  }

  /**
   * Returns the angle (in radias) between between formed between 3 points centered at this point
   */
  angle(p1: Point, p2: Point): number {
    const angle = Math.atan2(p1.y - this.y, p1.x - this.x) - Math.atan2(p2.y - this.y, p2.x - this.x)
    if (angle >= 0) {
      return angle < Math.PI ? angle : Math.PI * 2 - angle
    } else {
      return angle > -Math.PI ? -angle : Math.PI * 2 + angle
    }
  }

  /**
   * Given three points a, b, and c, determine whether they form a counterclockwise angle. The function ccw takes three Point inputs a, b, and c and returns +1 if a->b->c is a counterclockwise angle, -1 if a->b->c is a clockwise angle, and 0 if a->b->c are collinear
   */
  angleDirection(p1: Point, p2: Point): number {
    return (p1.x - this.x) * (p2.y - this.y) - (p2.x - this.x) * (p1.y - this.y)
  }

  /**
   * Rotate this point around a pivot point
   */
  rotate(rotationAngle: number, pivot: Point): Point {
    return this.transform(IDENTITY.translate(pivot.x, pivot.y).rotate(rotationAngle).translate(-pivot.x, -pivot.y))
  }

  /**
   * Apply this transform to the given point, returning the transformation
   * of the given point.
   * The mathematics is as follows:
   *    [  m11  m21  dx  ]  [ x ]
   *    [  m12  m22  dy  ]  [ y ]
   *    [   0    0    1  ]  [ 1 ]
   * Result is:
   *    [ m11 x + m21 y + dx ]
   *    [ m12 x + m22 y + dy ]
   *    [          1         ]
   */
  transform(t: AffineTransform): Point {
    return new Point(t.m11 * this.x + t.m21 * this.y + t.dx, t.m12 * this.x + t.m22 * this.y + t.dy)
  }
}
