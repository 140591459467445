import { IDENTITY } from 'Graphics/AffineTransform'
import Color from 'Graphics/Color'
import Path, { FillStyle, RadientGradientFill, SolidColorFill } from 'Graphics/Path'
import Point from 'Graphics/Point'
import Rect from 'Graphics/Rect'
import Utils from 'Graphics/Utils'
import { ArrayValue, BooleanValue, ColorValue, Config, EnumValue, NumberValue } from 'Playground/Config'
import { ShapeSpec, TilingSpec } from 'Projects/StarPatterns/Spec/TilingSpecs'
import GraphicsObjectGroup from '../../Graphics/GraphicsObjectGroup'

/**
 * Rendering style of stars
 */
export enum StarPatternStyle {
  FLAT, // Solid colors. Flat ribbons without interlacing.
  SOLID, // Solid colors. Interlaced ribbons.
  VIGNETTE, // Vignette color gradients. Interlaced ribbons
  INVERTED, // Interted color gradients. Interlaced ribbons
  TOXIC, // Complimentary color gradients. Interlaced ribbons.
}

/**
 * Represents the configuration for each star
 */
export type StarPatternConfig = {
  readonly spec: ArrayValue<TilingSpec>
  readonly zoom: NumberValue // percent value that indicates the overall zoom level with (100 - default zoom)
  readonly rotation: NumberValue // rotation angle from 0 to 360 degrees
  readonly starColor: ColorValue
  readonly skew: NumberValue // percent value that indicates the position within the valid contact angle range
  readonly ribbonColor: ColorValue
  readonly ribbonScale: NumberValue // percent value that indicates width of the ribbon compared to max allowed width
  readonly strokeColor: ColorValue
  readonly strokeScale: NumberValue // percent value that indicates width of the stroke compared to max allowed width
  readonly backgroundColor: ColorValue
}

export type StarPatternDebugConfig = {
  readonly style: EnumValue<StarPatternStyle>
  readonly density: NumberValue // average number of tiles in the screen at 100 scale
  readonly showTiles: BooleanValue // whether or not to show the tile backgrounds
  readonly noRibbons: BooleanValue // whether or not to show the ribbons
  readonly noRepeat: BooleanValue // whether or not tiles should be repeated
  readonly vignetteIntensity: NumberValue // percent value that indicates the darkness of the outer color of the vignette style
  readonly toxicIntensity: NumberValue // value from 0 to 180 that represents the HUE angle of outer color in the toxic style
}

/**
 * A composite Element that renders a full screen islamic star tiling
 */
export class StarPattern extends GraphicsObjectGroup {
  readonly config: Config<StarPatternConfig>
  readonly width: number
  readonly height: number

  constructor(
    center: Point,
    width: number,
    height: number,
    config: Config<StarPatternConfig>,
    debugConfig: Config<StarPatternDebugConfig>,
  ) {
    super()

    this.width = width
    this.height = height
    this.config = config

    // Validate
    if (width == 0 || height == 0) {
      return
    }

    const spec = config.values.spec.val
    // Compute actual versions of config numbers
    const scale =
      ((config.values.zoom.val / 100) * Math.min(width, height)) /
      spec.averageSideLength /
      debugConfig.values.density.val
    // console.log(`Scale: ${scale}`)
    const ribbonWidth = (config.values.ribbonScale.val / 100) * spec.maxRibbonWidth
    const ribbonForegroundWidth = (1 - config.values.strokeScale.val / 100) * ribbonWidth
    // console.log(`Ribbon width: ${ribbonWidth}`)
    const ribbonStrokeWidth = (config.values.strokeScale.val / 100 / 2) * ribbonWidth
    // console.log(`Ribbon border width: ${ribbonBorderWidth}`)
    const [minContactAngle, maxContactAngle] = spec.getContactAngleRange(ribbonWidth)
    // console.log(`Min contact angle: ${(minContactAngle * 180) / Math.PI}`)
    // console.log(`Max contact angle: ${(maxContactAngle * 180) / Math.PI}`)
    const contactAngle = minContactAngle + ((maxContactAngle - minContactAngle) * config.values.skew.val) / 100
    // console.log(`Contact angle: ${(contactAngle * 180) / Math.PI}`)

    // Background
    const backgroundRect = new Rect(0, 0, this.width, this.height).setFillStyle(
      colorToFillStyle(config.values.backgroundColor.val, debugConfig),
    )
    this.addChild(backgroundRect)

    // Create a tilegroup from all tiles, such that the center of the largest tile is
    // in the middle of the screen. This tile group will be repeated to fill the screen.
    const tileGroup = new Tiling(config, debugConfig, contactAngle, ribbonForegroundWidth, ribbonStrokeWidth)

    // Transform all tiles in a way where center of the tile group is at the center of the screen
    const starPattern = new GraphicsObjectGroup()
    starPattern.setTransform(
      IDENTITY.translate(center.x, center.y)
        .rotate((config.values.rotation.val * Math.PI) / 180)
        .scale(scale, scale)
        .translate(-spec.center.x, -spec.center.y),
    )
    this.addChild(starPattern)

    // We'll do a BFS using a queue starting at (0, 0), which is the the center of the screen, and keep
    // moving in all directions (up, down, left, right) until the group goes out of bounds.
    const bfsQueue: Point[] = [new Point(0, 0)] // Each point is the number of times to apply a translation
    const bfsVisited: Set<string> = new Set()
    const boundsTransform = starPattern.transform.invert()
    const bounds = new Path(backgroundRect.points.map((p) => p.transform(boundsTransform)))
    const boundsMin = bounds.getMinPoint()
    const boundsMax = bounds.getMaxPoint()

    while (bfsQueue.length > 0) {
      const shift = bfsQueue.shift()
      if (!shift || bfsVisited.has(shift.toString())) {
        // Already visited
        continue
      }
      bfsVisited.add(shift.toString())

      // Compute the coordinates of the origin of the tile group.
      const x = shift.x * spec.tx.x + shift.y * spec.ty.x
      const y = shift.x * spec.tx.y + shift.y * spec.ty.y

      // Check if the tile group is out of bounds horizontally
      if (x + spec.x + spec.width < boundsMin.x || x + spec.x > boundsMax.x) {
        continue
      }

      // Check if the tile group is out of bounds vertically
      if (y + spec.y + spec.height < boundsMin.y || y + spec.y > boundsMax.y) {
        continue
      }

      // Create a copy of the tile & move them to the origin
      starPattern.addChild(tileGroup.copy(IDENTITY.translate(x, y)))

      // Repeat in all directions
      if (!debugConfig.values.noRepeat.val) {
        bfsQueue.push(new Point(shift.x + 1, shift.y))
        bfsQueue.push(new Point(shift.x - 1, shift.y))
        bfsQueue.push(new Point(shift.x, shift.y + 1))
        bfsQueue.push(new Point(shift.x, shift.y - 1))
      }
    }
  }
}

class Tiling extends GraphicsObjectGroup {
  constructor(
    config: Config<StarPatternConfig>,
    debugConfig: Config<StarPatternDebugConfig>,
    contactAngle: number,
    ribbonInnerWidth: number,
    ribbonStrokeWidth: number,
  ) {
    super()
    config.values.spec.val.tiles.map((tileSpec, index) => {
      this.addChild(
        new Tile(
          config,
          debugConfig,
          tileSpec.shape,
          contactAngle,
          ribbonInnerWidth / tileSpec.scale,
          ribbonStrokeWidth / tileSpec.scale,
          Color.distictFromRange(index, config.values.spec.val.tiles.length),
        ).setTransform(tileSpec.transform),
      )
    })
  }
}

/**
 * Represents the star segment drawn on a tile
 */
class Tile extends GraphicsObjectGroup {
  constructor(
    config: Config<StarPatternConfig>,
    debugConfig: Config<StarPatternDebugConfig>,
    shape: ShapeSpec,
    contactAngle: number,
    ribbonInnerWidth: number,
    ribbonStrokeWidth: number,
    tileColor: Color,
  ) {
    super()

    // Add tile background
    const tileBackground = new Path(shape.points)
    if (debugConfig.values.showTiles.val) {
      tileBackground.setFillColor(tileColor)
      this.addChild(tileBackground)
    }

    // Add tile star & ribbons
    // For each 3 points in the array (p1, p2, p3) that form who join sides
    // of the polygon, compute two joining rays.
    const starPoints: Point[] = []
    const ribbons: Path[] = []
    const ribbonBorders: Path[] = []

    for (let i = 0; i <= shape.points.length; i++) {
      // Iterate until bounds length to complete the full loop for interlacing
      const p = shape.points[i % shape.points.length]

      // Contact point on the previous side
      const contactPrev = shape.contactPoints[(i + shape.points.length - 1) % shape.points.length]
      const contactDistancePrev = contactPrev.distanceTo(p)

      // Contact point on the next side
      const contactNext = shape.contactPoints[i % shape.points.length]
      const contactDistanceNext = contactNext.distanceTo(p)

      // Ribbon contact points at the previous side
      const ribbonScalePrev = ribbonInnerWidth / 2 / Math.sin(contactAngle) / contactDistancePrev
      const ribbonPrev1 = contactPrev.shift(p, ribbonScalePrev)
      const ribbonPrev2 = contactPrev.shift(p, -ribbonScalePrev)

      // Border contact points at the previous side
      const borderScalePrev = (ribbonInnerWidth / 2 + ribbonStrokeWidth) / Math.sin(contactAngle) / contactDistancePrev
      const borderPrev1 = contactPrev.shift(p, borderScalePrev)
      const borderPrev2 = contactPrev.shift(p, -borderScalePrev)

      // Ribbon contact points at the next side
      const ribbonScaleNext = ribbonInnerWidth / 2 / Math.sin(contactAngle) / contactDistanceNext
      const ribbonNext1 = contactNext.shift(p, ribbonScaleNext)
      const ribbonNext2 = contactNext.shift(p, -ribbonScaleNext)

      // Border contact points at the previous side
      const borderScaleNext = (ribbonInnerWidth / 2 + ribbonStrokeWidth) / Math.sin(contactAngle) / contactDistanceNext
      const borderNext1 = contactNext.shift(p, borderScaleNext)
      const borderNext2 = contactNext.shift(p, -borderScaleNext)

      // Intersection points (middle, ribbon & borders)
      const intersection = Utils.completePolygon(p, contactPrev, contactNext, contactAngle)
      const ribbonIntersection1 = Utils.completePolygon(p, ribbonPrev1, ribbonNext1, contactAngle)
      const ribbonIntersection2 = Utils.completePolygon(p, ribbonPrev2, ribbonNext2, contactAngle)
      const borderIntersection1 = Utils.completePolygon(p, borderPrev1, borderNext1, contactAngle)
      const borderIntersection2 = Utils.completePolygon(p, borderPrev2, borderNext2, contactAngle)

      if (i < shape.points.length) {
        ribbonBorders.push(
          new Path([
            borderPrev1,
            borderIntersection1,
            borderNext1,
            ribbonNext1,
            ribbonIntersection1,
            ribbonPrev1,
          ]).setFillColor(config.values.strokeColor.val),
        )
        ribbonBorders.push(
          new Path([
            ribbonPrev2,
            ribbonIntersection2,
            ribbonNext2,
            borderNext2,
            borderIntersection2,
            borderPrev2,
          ]).setFillColor(config.values.strokeColor.val),
        )
        ribbons.push(
          new Path([
            ribbonPrev1,
            ribbonPrev2,
            ribbonIntersection2,
            ribbonNext2,
            ribbonNext1,
            ribbonIntersection1,
          ]).setFillStyle(colorToFillStyle(config.values.ribbonColor.val, debugConfig)),
        )
      } else {
        // Only include the first half when overflowing to account for correct interlacing
        ribbonBorders.push(
          new Path([borderPrev1, borderIntersection1, ribbonIntersection1, ribbonPrev1]).setFillColor(
            config.values.strokeColor.val,
          ),
        )
        ribbonBorders.push(
          new Path([ribbonPrev2, ribbonIntersection2, borderIntersection2, borderPrev2]).setFillColor(
            config.values.strokeColor.val,
          ),
        )
        ribbons.push(
          new Path([ribbonPrev1, ribbonPrev2, ribbonIntersection2, ribbonIntersection1]).setFillStyle(
            colorToFillStyle(config.values.ribbonColor.val, debugConfig),
          ),
        )
      }

      // Update the points of the star
      if (i < shape.points.length) {
        starPoints.push(contactPrev)
        if (intersection) {
          starPoints.push(intersection)
        }
      }
    }

    // Create star foreground
    this.addChild(new Path(starPoints).setFillStyle(colorToFillStyle(config.values.starColor.val, debugConfig)))

    // Create star ribbon
    if (!debugConfig.values.noRibbons.val) {
      if (ribbonStrokeWidth == 0) {
        this.addChildren(ribbons)
      } else if (debugConfig.values.style.val == StarPatternStyle.FLAT) {
        this.addChildren(ribbonBorders)
        this.addChildren(ribbons)
      } else {
        for (let i = 0; i < ribbons.length; i++) {
          this.addChild(ribbonBorders[i * 2])
          this.addChild(ribbonBorders[i * 2 + 1])
          this.addChild(ribbons[i])
        }
      }
    }
  }
}

/**
 * Apply style to color
 */
function colorToFillStyle(color: Color, config: Config<StarPatternDebugConfig>): FillStyle {
  if (config.values.style.val == StarPatternStyle.VIGNETTE) {
    return new RadientGradientFill(
      color,
      color.darken(config.values.vignetteIntensity.val / 100),
      true /* relative to screen */,
    )
  } else if (config.values.style.val == StarPatternStyle.INVERTED) {
    return new RadientGradientFill(color, color.invert(), true /* relative to screen */)
  } else if (config.values.style.val == StarPatternStyle.TOXIC) {
    return new RadientGradientFill(
      color,
      color.complimentary(config.values.toxicIntensity.val),
      true /* relative to screen */,
    )
  } else {
    return new SolidColorFill(color)
  }
}
