import AffineTransform from 'Graphics/AffineTransform'
import Point from 'Graphics/Point'
import TilingSpecsJSON from 'Projects/StarPatterns/Spec/TilingSpecs.json'

type TilingGroupJSON = {
  readonly name: string
  readonly tilings: TilingJSON[]
}

type TilingJSON = {
  readonly name: string
  readonly translations: TranslationJSON[]
  readonly tiles: TileJSON[]
}

type TileJSON = {
  readonly shape: ShapeJSON
  readonly transforms: TransformJSON[]
}

type ShapeJSON = {
  readonly type: ShapeTypeJSON
  readonly sides?: number
  readonly vertices?: VertexJSON[]
}

type TranslationJSON = {
  readonly x: number
  readonly y: number
}

type VertexJSON = {
  readonly x: number
  readonly y: number
  readonly contact?: number
}

enum ShapeTypeJSON {
  Regular = 'REGULAR',
  Polygon = 'POLYGON',
}

type TransformJSON = {
  readonly a: number
  readonly b: number
  readonly c: number
  readonly d: number
  readonly e: number
  readonly f: number
}

/**
 * Represents tiling configuration
 */
export class TilingSpec {
  readonly specIndex: number
  readonly specName: string
  readonly tiles: TileSpec[]
  readonly tx: Point
  readonly ty: Point
  readonly center: Point // center of the tiling is the center of the largest tile
  readonly width: number
  readonly height: number
  readonly x: number
  readonly y: number
  readonly maxRibbonWidth: number
  readonly averageSideLength: number

  constructor(specIndex: number, spec: TilingJSON) {
    this.specIndex = specIndex
    this.specName = spec.name
    this.tx = new Point(spec.translations[0].x, spec.translations[0].y)
    this.ty = new Point(spec.translations[1].x, spec.translations[1].y)

    // Create shapes for each of the tiles (shapes are shared across tile configs )
    const shapeConfigs = spec.tiles.map((tileSpec) => new ShapeSpec(tileSpec.shape))

    // Create tiles across all configs & transforms
    this.tiles = []
    let tileIndex = 0
    for (let i = 0; i < spec.tiles.length; i++) {
      for (let j = 0; j < spec.tiles[i].transforms.length; j++) {
        this.tiles.push(new TileSpec(tileIndex++, shapeConfigs[i], spec.tiles[i].transforms[j]))
      }
    }

    // Precompute various properties
    this.averageSideLength = 0
    this.maxRibbonWidth = Number.MAX_VALUE
    let largestTileIndex = 0
    let minX = Number.MAX_VALUE
    let maxX = 0
    let minY = Number.MAX_VALUE
    let maxY = 0
    for (let i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i]
      this.averageSideLength = this.averageSideLength + this.tiles[i].averageSideLength
      this.maxRibbonWidth = Math.min(this.maxRibbonWidth, tile.maxRibbonWidth)
      if (tile.width > this.tiles[largestTileIndex].width) {
        largestTileIndex = i
      }
      minX = Math.min(minX, tile.x)
      maxX = Math.max(maxX, tile.x + tile.width)
      minY = Math.min(minY, tile.y)
      maxY = Math.max(maxY, tile.y + tile.height)
    }
    this.averageSideLength /= this.tiles.length
    this.center = this.tiles[largestTileIndex].center
    this.x = minX
    this.y = minY
    this.width = maxX - minX
    this.height = maxY - minY
  }

  /**
   * Compute the contact angle range
   */
  getContactAngleRange(ribbonWidth: number): number[] {
    return this.tiles.reduce(
      (range, t) => {
        const tRange = t.getContactAngleRange(ribbonWidth)
        return [Math.max(range[0], tRange[0]), Math.min(range[1], tRange[1])]
      },
      [0, Number.MAX_VALUE],
    )
  }
}

/**
 * Tile configuration (ie: transformed shape)
 */
export class TileSpec {
  readonly name: string
  readonly shape: ShapeSpec
  readonly transform: AffineTransform
  readonly scale: number
  readonly center: Point
  readonly width: number
  readonly height: number
  readonly x: number
  readonly y: number
  readonly maxRibbonWidth: number
  readonly averageSideLength: number

  constructor(tileIndex: number, shape: ShapeSpec, transformSpec: TransformJSON) {
    this.name = `Tile ${tileIndex}: ${shape.name}`
    this.shape = shape
    this.transform = new AffineTransform(
      transformSpec.a,
      transformSpec.d,
      transformSpec.b,
      transformSpec.e,
      transformSpec.c,
      transformSpec.f,
    )
    // Precompute a bunch of parameters once to make rendering faster
    this.scale = Math.sqrt(Math.pow(transformSpec.a, 2) + Math.pow(transformSpec.d, 2))
    this.maxRibbonWidth = this.shape.maxRibbonWidth * this.scale
    this.averageSideLength = this.shape.averageSideLength * this.scale
    this.center = this.shape.center.transform(this.transform)
    let minX = Number.MAX_VALUE
    let maxX = 0
    let minY = Number.MAX_VALUE
    let maxY = 0
    this.shape.points
      .map((p) => p.transform(this.transform))
      .forEach((p) => {
        minX = Math.min(minX, p.x)
        maxX = Math.max(maxX, p.x)
        minY = Math.min(minY, p.y)
        maxY = Math.max(maxY, p.y)
      })
    this.x = minX
    this.y = minY
    this.width = maxX - minX
    this.height = maxY - minY
  }

  /**
   * Compute the contact angle range
   */
  getContactAngleRange(ribbonWidth: number): number[] {
    return this.shape.getContactAngleRange(ribbonWidth / this.scale)
  }
}

/**
 * Tile shape configuration
 */
export class ShapeSpec {
  readonly name: string
  readonly points: Point[]
  readonly contactPoints: Point[]
  readonly center: Point
  readonly maxRibbonWidth: number
  readonly averageSideLength: number

  constructor(shape: ShapeJSON) {
    const points: Point[] = []
    const contactPoints: Point[] = []
    if (shape.type == ShapeTypeJSON.Regular && shape.sides) {
      this.name = `Regular(sides=${shape.sides})`
      const rotationAngle = (2 * Math.PI) / shape.sides
      const sideLength = 2 * Math.tan(rotationAngle / 2)
      let p = new Point(1, -sideLength / 2)
      for (let i = 0; i < shape.sides; i++) {
        points.push(p)
        const pNext = new Point(
          p.x + sideLength * Math.cos(Math.PI / 2 + rotationAngle * i),
          p.y + sideLength * Math.sin(Math.PI / 2 + rotationAngle * i),
        )
        contactPoints.push(p.shift(pNext, 0.5))
        p = pNext
      }
    } else if (shape.type == ShapeTypeJSON.Polygon && shape.vertices) {
      this.name = `Polygon(vertices=${shape.vertices.length})`
      for (let i = 0; i < shape.vertices.length; i++) {
        const v = shape.vertices[i]
        const vNext = shape.vertices[(i + 1) % shape.vertices.length]
        const p = new Point(v.x, v.y)
        const pNext = new Point(vNext.x, vNext.y)
        points.push(p)
        contactPoints.push(p.shift(pNext, v.contact || 0.5))
      }
    } else {
      throw new TypeError(`Invalid shape: ${shape}`)
    }
    this.points = points
    this.contactPoints = contactPoints
    // Compute center of the shape
    this.center = points.reduce(
      (center, p) => new Point(center.x + p.x / points.length, center.y + p.y / points.length),
      new Point(0, 0),
    )
    // Compute average side length & max ribbon width
    this.averageSideLength = 0
    this.maxRibbonWidth = Number.MAX_VALUE
    for (let i = 0; i < this.points.length; i++) {
      const p = this.points[i]
      const contact = this.contactPoints[i]
      const pNext = this.points[(i + 1) % this.points.length]
      this.averageSideLength += p.distanceTo(pNext)
      // Find the min contact length (distance from a contant point to a vertex)
      const contactLength = Math.min(contact.distanceTo(p), contact.distanceTo(pNext))
      // Find the min distance from a contact point to center
      const centerLength = contact.distanceTo(this.center)
      // Assume that the total ribbon width cannot be larger than the min contact length
      this.maxRibbonWidth = Math.min(this.maxRibbonWidth, Math.min(centerLength * 2, contactLength * 2))
    }
    this.averageSideLength /= this.points.length
  }

  /**
   * Compute the contact angle range
   */
  getContactAngleRange(ribbonWidth: number): number[] {
    // Find max & min contact angle based on ribbon width
    let minContactAngle = 0
    let maxContactAngle = Math.PI / 2
    for (let i = 0; i < this.points.length; i++) {
      // Iterate until bounds length to complete the full loop for interlacing
      const p = this.points[i]
      const contactPrev = this.contactPoints[(i + this.points.length - 1) % this.points.length]
      const contactNext = this.contactPoints[i]

      // Find the min distance from a contant point to a vertex
      minContactAngle = Math.max(minContactAngle, Math.asin(ribbonWidth / 2 / contactNext.distanceTo(p)))

      // Find the min distance from a contact point to center
      const centerLength = contactNext.distanceTo(this.center)
      const centerContactAnglePrev = contactNext.angle(p, this.center)
      const centerContactAngleNext = contactPrev.angle(p, this.center)
      if (centerContactAnglePrev == centerContactAngleNext) {
        maxContactAngle = Math.min(maxContactAngle, Math.atan((centerLength / ribbonWidth) * 2))
      } else if (centerContactAnglePrev < centerContactAngleNext) {
        maxContactAngle = Math.min(
          maxContactAngle,
          Math.abs(centerContactAngleNext - Math.asin(ribbonWidth / 2 / centerLength)),
        )
      } else {
        maxContactAngle = Math.min(
          maxContactAngle,
          Math.abs(centerContactAnglePrev - Math.asin(ribbonWidth / 2 / centerLength)),
        )
      }
    }
    return [minContactAngle, Math.max(minContactAngle, maxContactAngle)]
  }
}

/**
 * List of all tiling specs from the JSON spec file.
 */
export const TILING_SPECS = (TilingSpecsJSON as TilingGroupJSON[])
  .flatMap((group) => group.tilings)
  .map((tilingSpec, index) => new TilingSpec(index, tilingSpec))
