import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CopyrightIcon from '@mui/icons-material/Copyright'
import MenuIcon from '@mui/icons-material/Menu'
import PauseIcon from '@mui/icons-material/Pause'
import PlayIcon from '@mui/icons-material/PlayArrow'
import RestartIcon from '@mui/icons-material/RestartAlt'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import {
  alpha,
  AppBar,
  Box,
  Button,
  createTheme,
  CssBaseline,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { Stack } from '@mui/system'
import CanvasRenderer from 'Graphics/Renderers/CanvasRenderer'
import { Config, ConfigValues, NumberValue } from 'Playground/Config'
import RenderingCanvas from 'Playground/RenderingCanvas'
import Projects from 'Projects/Projects'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import ControlPanel from './ControlPanel'
import Project from './Project'

const SecondaryControlButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.9),
  margin: 5,
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.background.default,
  },
  '& svg': {
    margin: 5,
    fontSize: 40,
  },
}))

const PrimaryControlButton = styled(SecondaryControlButton)<IconButtonProps>(({ theme }) => ({
  '& svg': {
    fontSize: 50,
  },
}))

interface PlaygroundConfig extends ConfigValues {
  readonly animationLength: NumberValue // percent value of the animation length within the valid range
}

interface PlaygroundProps {
  project: Project<any, any>
}

function Playground<T extends ConfigValues>({ project }: PlaygroundProps) {
  // Default theme
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#222222',
      },
    },
  })
  // Playground config
  const defaultPlaygroundConfig = new Config({
    animationLength: new NumberValue(
      20, // min, seconds
      60, // default, seconds
      120, // max, seconds
    ),
  } as PlaygroundConfig)
  const [playgroundConfig, setPlaygroundConfig] = useQueryParam('p', defaultPlaygroundConfig.asQueryParam())
  // Project config
  const [projectConfig, setProjectConfig] = useQueryParam('c', project.defaultConfig.asQueryParam())
  const [projectDebugConfig, setProjectDebugConfig] = useQueryParam('d', project.defaultDebugConfig.asQueryParam())
  // Other state
  const [isAnimationActive, setIsAnimationActive] = useState(false)
  const [showDebugControls] = useQueryParam('debug', withDefault(BooleanParam, process.env.NODE_ENV != 'production'))
  const [showMenu, setShowMenu] = useState(false)
  // Default renderer
  const renderer = new CanvasRenderer()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack sx={{ width: 1, height: 1, position: 'absolute' }}>
        <AppBar position='static' sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <Toolbar variant='dense'>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 1, display: 'flex' }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ display: { xs: 'none', lg: 'flex' } }}>
              Graphics Playground
            </Typography>
            <ChevronRightIcon sx={{ ml: 1, mr: 1, display: { xs: 'none', lg: 'flex' } }} />
            <Typography component='div' sx={{ flexGrow: 1 }}>
              {project.name}
            </Typography>
            <Button
              color='inherit'
              href='https://twitter.com/tokudu'
              sx={{ ml: 4, display: { xs: 'none', lg: 'flex' } }}
            >
              <Stack direction='row' justifyContent='center'>
                <CopyrightIcon fontSize='small' sx={{ mr: 0.5 }} />
                <Typography variant='body2'>TOKUDU 2023</Typography>
              </Stack>
            </Button>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          anchor='left'
          open={showMenu}
          onClose={() => setShowMenu(false)}
          onOpen={() => setShowMenu(true)}
          hideBackdrop={true}
          sx={{ mt: theme.mixins.toolbar.height }}
        >
          <Box sx={{ width: 250 }} role='presentation'>
            <Toolbar variant='dense' />
            <ListSubheader component='div'>Projects</ListSubheader>
            <List sx={{ pt: 0, pb: 0 }}>
              {Projects.ALL.map((project) => (
                <ListItem key={project.id} disablePadding>
                  <ListItemButton component={Link} to={`/${project.id}`}>
                    <ListItemText primary={project.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {playgroundConfig.keys.length > 0 && (
              <ControlPanel
                title='Playground Settings'
                config={playgroundConfig}
                defaultConfig={defaultPlaygroundConfig}
                onConfigChange={(config) => setPlaygroundConfig(config)}
                disabled={isAnimationActive}
              />
            )}
            {projectConfig.keys.length > 0 && (
              <ControlPanel
                title='Project Settings'
                config={projectConfig}
                defaultConfig={project.defaultConfig}
                onConfigChange={(config) => setProjectConfig(config)}
                disabled={isAnimationActive}
              />
            )}
            {showDebugControls && projectDebugConfig.keys.length > 0 && (
              <ControlPanel
                title='Debug Settings'
                config={projectDebugConfig}
                defaultConfig={project.defaultDebugConfig}
                onConfigChange={(config) => setProjectDebugConfig(config)}
                disabled={isAnimationActive}
              />
            )}
          </Box>
        </SwipeableDrawer>
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <RenderingCanvas
            sx={{ width: 1, height: 1, position: 'absolute' }}
            renderer={renderer}
            animationLengthMillis={isAnimationActive ? playgroundConfig.values.animationLength.val * 1000 : undefined}
            onRefresh={(width, height, animationPosition) => {
              const config = animationPosition !== undefined ? projectConfig.animate(animationPosition) : projectConfig
              return project.renderObject(width, height, config, projectDebugConfig)
            }}
          />
          <Box
            sx={{
              width: 1,
              bottom: 0,
              position: 'absolute',
              justifyContent: 'center',
              display: project.controlsEnabled ? 'inline-flex' : 'none',
              alignItems: 'center',
            }}
          >
            <SecondaryControlButton onClick={() => setIsAnimationActive(!isAnimationActive)}>
              {isAnimationActive ? <PauseIcon /> : <PlayIcon />}
            </SecondaryControlButton>
            <PrimaryControlButton size='large' onClick={() => setProjectConfig(projectConfig.randomize())}>
              <ShuffleIcon />
            </PrimaryControlButton>
            <SecondaryControlButton onClick={() => setProjectConfig(project.defaultConfig)}>
              <RestartIcon />
            </SecondaryControlButton>
          </Box>
        </Box>
      </Stack>
    </ThemeProvider>
  )
}

export default Playground
