import AffineTransform from 'Graphics/AffineTransform'
import Path, { RadientGradientFill } from 'Graphics/Path'
import Point from 'Graphics/Point'
import Renderer from '../Renderer'

/**
 * Render that uses raw html canvas
 */
export default class CanvasRenderer extends Renderer {
  /**
   * Main canvas used for rendering
   */
  private canvas?: HTMLCanvasElement
  /**
   * Buffer canvas used for avoiding flicker during resizing
   */
  private buffer?: HTMLCanvasElement

  init(container: HTMLDivElement) {
    super.init(container)
    this.canvas = document.createElement('canvas')
    container.appendChild(this.canvas)
    this.buffer = document.createElement('canvas')
    container.appendChild(this.buffer)
    this.resize(container.offsetWidth, container.offsetHeight)
  }

  dispose(): void {
    super.dispose()
    this.canvas?.remove()
    this.canvas = undefined
    this.buffer?.remove()
    this.buffer = undefined
  }

  resize(width: number, height: number): void {
    if (!this.canvas || !this.buffer) {
      throw Error('Not initialized')
    }
    super.resize(width, height)
    // Increase the canvas size on devices with high pixel ratio
    // Source: https://dev.to/pahund/how-to-fix-blurry-text-on-html-canvases-on-mobile-phones-3iep
    const ratio = Math.ceil(window.devicePixelRatio)
    const setCanvasSize = (canvas: HTMLCanvasElement) => {
      canvas.width = width * ratio
      canvas.height = height * ratio
      canvas.style.width = `${width}px`
      canvas.style.height = `${height}px`
      canvas.style.position = 'absolute'
      canvas.getContext('2d')?.resetTransform()
    }

    // Resize the temp canvas
    setCanvasSize(this.buffer)
    // Draw into the temp canvas
    this.buffer.getContext('2d')?.drawImage(this.canvas, 0, 0)
    // Swam in temp canvas
    this.buffer.style.display = 'block'
    this.canvas.style.display = 'none'
    // Resize the main canvas
    setCanvasSize(this.canvas)
    // Draw back to the main canvas
    this.canvas.getContext('2d')?.drawImage(this.buffer, 0, 0)
    // Swap back
    this.canvas.style.display = 'block'
    this.buffer.style.display = 'none'
    this.canvas.getContext('2d')?.setTransform(ratio, 0, 0, ratio, 0, 0)
  }

  startGroup(transform: AffineTransform): void {
    if (!this.canvas) {
      throw Error('Not initialized')
    }
    const ctx = this.canvas.getContext('2d')
    if (ctx == null) {
      return
    }
    super.startGroup(transform)
    ctx.save()
    ctx.transform(transform.m11, transform.m12, transform.m21, transform.m22, transform.dx, transform.dy)
  }

  endGroup(): void {
    if (!this.canvas) {
      throw Error('Not initialized')
    }
    const ctx = this.canvas.getContext('2d')
    if (ctx == null) {
      return
    }
    super.endGroup()
    ctx.restore()
  }

  renderPath(path: Path) {
    if (!this.canvas) {
      throw Error('Not initialized')
    }
    const ctx = this.canvas.getContext('2d')
    if (path.points.length == 0 || ctx == null) {
      return
    }

    // Helper for rendering points
    const renderPoints = (points: Point[]) => {
      points.forEach((p, index) => {
        // convert to ints to work around anti-aliasing issues
        const x = p.x
        const y = p.y
        if (index == 0) {
          ctx.moveTo(x, y)
        } else {
          ctx.lineTo(x, y)
        }
      })
    }
    // Render Fill
    if (path.fillStyle && path.points.length > 1) {
      if (path.fillStyle instanceof RadientGradientFill) {
        let gradientCenter: Point
        let gradientRadius: number
        if (path.fillStyle.relativeToScreen) {
          gradientCenter = this.state.screenGradientCenter
          gradientRadius = this.state.screenGradientRadius
        } else {
          gradientCenter = path.getCenterPoint()
          const pathMaxPoint = path.getMaxPoint()
          gradientRadius = Math.max(
            gradientCenter.distanceTo(new Point(gradientCenter.x, pathMaxPoint.y)),
            gradientCenter.distanceTo(new Point(pathMaxPoint.x, gradientCenter.y)),
          )
        }
        const gradient = ctx.createRadialGradient(
          gradientCenter.x,
          gradientCenter.y,
          0,
          gradientCenter.x,
          gradientCenter.y,
          gradientRadius,
        )
        gradient.addColorStop(0, path.fillStyle.color.toRGBA())
        gradient.addColorStop(1, path.fillStyle.endColor.toRGBA())
        ctx.fillStyle = gradient
      } else {
        ctx.fillStyle = path.fillStyle.color.toRGBA()
      }
      ctx.beginPath()
      renderPoints(path.points)
      ctx.fill()
    }
    // Render Stroke
    if (path.strokeWidth && path.strokeColor) {
      if (path.points.length > 1) {
        ctx.strokeStyle = path.strokeColor.toRGBA()
        ctx.lineWidth = path.strokeWidth
        ctx.beginPath()
        renderPoints(path.points)
        ctx.stroke()
      } else {
        // Draw a single point with a stroke
        ctx.fillStyle = path.strokeColor.toRGBA()
        ctx.arc(path.points[0].x, path.points[0].x, path.strokeWidth, 0, 2 * Math.PI)
      }
    }
  }

  clear(): void {
    if (!this.canvas) {
      throw Error('Not initialized')
    }
    this.canvas.getContext('2d')?.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
}
