import { QueryParamConfig, withDefault } from 'use-query-params'
import Color from '../Graphics/Color'

/**
 * Config values type
 */
export type ConfigValues = {
  [name: string]: ConfigValue<any, ArrayValue<any> | ColorValue | BooleanValue | NumberValue | EnumValue<any>>
}

/**
 * Represents a config, which can be safely serialized into a string
 */
export class Config<T extends ConfigValues> {
  constructor(values: T) {
    this.values = values
    this.keys = Object.keys(this.values)
  }

  /**
   * Map of config values
   */
  readonly values: T

  /**
   * List of keys in the order they were added
   */
  readonly keys: Array<keyof T>

  /**
   * Convert to query param configuration
   */
  asQueryParam(): QueryParamConfig<Config<T>> {
    return withDefault(
      {
        encode: (value: Config<T>): string => {
          return value.encode()
        },
        decode: (value: string | (string | null)[] | null | undefined): Config<T> => {
          return typeof value === 'string' ? this.decode(value) : this
        },
      },
      this,
    )
  }

  /**
   * Create a copy with edited values
   */
  edit(newValues: Partial<T>): Config<T> {
    return new Config({
      ...this.values,
      ...newValues,
    })
  }

  /**
   * Checks if the config is valid by checking all internal values.
   */
  isValid(): boolean {
    for (const configKey in this.values) {
      const configVal = this.values[configKey]
      if (!configVal.isValid()) {
        return false
      }
    }
    return true
  }

  /**
   * Generate random config
   */
  randomize(): Config<T> {
    const randomSeed = Math.random()
    let randomValues: Partial<T> = {}
    for (const configKey in this.values) {
      const configVal = this.values[configKey]
      if (configVal.props.random !== undefined) {
        randomValues = { ...randomValues, [configKey]: configVal.edit(configVal.props.random(randomSeed)) }
      }
    }
    return new Config({ ...this.values, ...randomValues })
  }

  /**
   * Generate a new config given an animation position. Returns the provides config, if animation is not active.
   */
  animate(animationPosition: number): Config<T> {
    let animatedValues: Partial<T> = {}
    for (const configKey in this.values) {
      const configVal = this.values[configKey]
      if (configVal.props.animate !== undefined) {
        animatedValues = {
          ...animatedValues,
          [configKey]: configVal.edit(configVal.props.animate(configVal.val, animationPosition)),
        }
      }
    }
    return new Config({ ...this.values, ...animatedValues })
  }

  /**
   * Efficiently encode config into a string
   */
  encode(): string {
    return this.keys
      .map((configKey) => {
        const configVal = this.values[configKey]
        let encodedVal = configVal.toString()
        if (configVal instanceof ConfigValue) {
          encodedVal = configVal.encode()
        } else {
          encodedVal = ''
        }
        return encodedVal
      })
      .join('')
  }

  /**
   * Decode config from efficiently encoded string
   */
  decode(rep: string): Config<T> {
    try {
      const decodeStr = (length: number): string => {
        if (length > rep.length) {
          throw new Error('Encoding is invalid.')
        }
        const val = rep.substring(0, length)
        rep = rep.substring(length)
        return val
      }
      let decodedValues: Partial<T> = {}
      for (let i = 0; i < this.keys.length; i++) {
        const configKey = this.keys[i]
        const configVal = this.values[configKey]
        decodedValues = { ...decodedValues, [configKey]: configVal.decode(decodeStr(configVal.encodingLength)) }
      }
      return this.edit(decodedValues)
    } catch {
      return this
    }
  }
}

/**
 * Optional properties of all config values
 */
export interface ConfigValueProps<C> {
  /**
   * Generate a random value based on the provided seed.
   */
  readonly random?: (seed: number) => C

  /**
   * Generate an animated value based on the position in [0-1 range].
   * For best results, the animation should be a closed loop and the value at 0 and 1 are equal.
   */
  readonly animate?: (currentValue: C, animationPosition: number) => C
}

/**
 * Represent all config values
 */
export abstract class ConfigValue<T, C extends ConfigValue<T, C>> {
  constructor(val: T, encodingLength: number, props: ConfigValueProps<T> = {}) {
    this.val = val
    this.encodingLength = encodingLength
    this.props = props
  }

  /**
   * Current value
   */
  readonly val: T

  /**
   * Max number of hex characters in the encoded value
   */
  readonly encodingLength: number

  /**
   * Max number of hex characters in the encoded value
   */
  readonly props: ConfigValueProps<T>

  /**
   * Returns whether or not the config value is valid
   */
  abstract isValid(): boolean

  /**
   * Creates a copy with a new value
   */
  abstract edit(newVal: T): C

  /**
   * Encodes the config value to string
   */
  abstract encode(): string

  /**
   * Creates a copy by decoding the value string
   */
  abstract decode(encodedVal: string): C
}

/**
 * Represents a number config value
 */
export class NumberValue extends ConfigValue<number, NumberValue> {
  readonly min: number
  readonly max: number
  readonly position: number

  constructor(min: number, val: number, max: number, props: ConfigValueProps<number> = {}) {
    super(val, Math.max(1, Math.ceil(Math.log(max) / Math.log(16))) /* encoding length */, props)
    this.min = min
    this.max = max
    this.position = (val - min) / (max - min)
  }

  isValid(): boolean {
    return !isNaN(this.val) && this.val >= this.min && this.val <= this.max
  }

  edit(newVal: number): NumberValue {
    return new NumberValue(this.min, newVal, this.max, this.props)
  }

  editPosition(position: number): NumberValue {
    return this.edit(this.min + position * (this.max - this.min + 1))
  }

  animate(position: number, speed = 1, alternate = false): NumberValue {
    const initialPosition = (this.val - this.min + 1) / (this.max - this.min + 1)
    // Apply speed
    position = position * speed - Math.floor(position * speed)
    // Apply initial position
    position += alternate ? initialPosition / 2 : initialPosition
    position = position > 1 ? position - 1 : position
    // Apply alternate (first 0.5 is forward, last 0.5 is backwards)
    if (alternate) {
      position = position > 0.5 ? (1 - position) * 2 : position * 2
    }
    // Apply range
    return this.editPosition(position)
  }

  encode(): string {
    return Math.ceil(this.val).toString(16).padStart(this.encodingLength, '0')
  }

  decode(encodedVal: string): NumberValue {
    if (encodedVal.length != this.encodingLength) {
      throw new Error(`Invalid encoding value length: ${encodedVal.length}. Expected: ${this.encodingLength}`)
    }
    return this.edit(parseInt(encodedVal, 16))
  }
}

/**
 * Represents a boolean value
 */
export class BooleanValue extends ConfigValue<boolean, BooleanValue> {
  private numValue: NumberValue

  constructor(val: boolean, props: ConfigValueProps<boolean> = {}) {
    const numValue = new NumberValue(0, val ? 1 : 0, 2)
    super(val, numValue.encodingLength, props)
    this.numValue = numValue
  }

  isValid(): boolean {
    return this.numValue.isValid()
  }

  edit(newVal: boolean): BooleanValue {
    return new BooleanValue(newVal, this.props)
  }

  flip(): BooleanValue {
    return this.edit(!this.val)
  }

  encode(): string {
    return this.numValue.encode()
  }

  decode(encodedVal: string): BooleanValue {
    return this.edit(this.numValue.decode(encodedVal).val == 1)
  }
}

/**
 * Represents a color config value
 */
export class ColorValue extends ConfigValue<Color, ColorValue> {
  constructor(val: Color, props: ConfigValueProps<Color> = {}) {
    super(val, 6 /* encoding length */, props)
  }

  isValid(): boolean {
    return true
  }

  edit(newVal: Color): ColorValue {
    return new ColorValue(newVal, this.props)
  }

  encode(): string {
    return this.val.toHex()
  }
  decode(encodedVal: string): ColorValue {
    if (encodedVal.length != this.encodingLength) {
      throw new Error(`Invalid encoding value length: ${encodedVal.length}. Expected: ${this.encodingLength}`)
    }
    return this.edit(Color.fromHex(encodedVal))
  }
}

/**
 * Represents a value from a static array
 */
export class ArrayValue<T> extends ConfigValue<T, ArrayValue<T>> {
  readonly values: T[]
  readonly indexOf: (val: T) => number
  readonly labelOf: (val: T) => string
  private numValue: NumberValue

  constructor(
    value: T,
    values: T[],
    indexOf: (val: T) => number,
    labelOf: (val: T) => string,
    props: ConfigValueProps<T> = {},
  ) {
    const numValue = new NumberValue(0, values.indexOf(value), values.length)
    super(value, numValue.encodingLength, props)
    this.values = values
    this.numValue = numValue
    this.indexOf = indexOf
    this.labelOf = labelOf
  }

  get index(): number {
    return this.numValue.val
  }

  isValid(): boolean {
    return this.numValue.isValid()
  }

  edit(newVal: T): ArrayValue<T> {
    return new ArrayValue<T>(newVal, this.values, this.indexOf, this.labelOf, this.props)
  }

  editWithIndex(newIndex: number): ArrayValue<T> {
    if (newIndex >= this.values.length) {
      throw new Error(`Invalid value index: ${newIndex}. Max: ${this.values.length - 1}`)
    }
    return this.edit(this.values[newIndex])
  }

  encode(): string {
    return this.numValue.encode()
  }

  decode(encodedVal: string): ArrayValue<T> {
    return this.editWithIndex(this.numValue.decode(encodedVal).val)
  }
}

/**
 * Represents an enum value
 */
export class EnumValue<T extends number> extends ConfigValue<T, EnumValue<T>> {
  readonly values: string[]
  private numValue: NumberValue

  constructor(val: T, values: string[], props: ConfigValueProps<T> = {}) {
    const numValue = new NumberValue(0, val, values.length)
    super(val, numValue.encodingLength, props)
    this.numValue = numValue
    this.values = values
  }

  isValid(): boolean {
    return this.numValue.isValid()
  }

  edit(newVal: T): EnumValue<T> {
    return new EnumValue<T>(newVal, this.values, this.props)
  }

  encode(): string {
    return this.numValue.encode()
  }

  decode(encodedVal: string): EnumValue<T> {
    return this.edit(this.numValue.decode(encodedVal).val as T)
  }
}

/**
 * Animation helpers
 */
export const animateValue = (
  min: number,
  val: number,
  max: number,
  pos: number,
  speed = 1,
  alternate = false,
): number => {
  const initialPosition = (val - min) / (max - min)
  // Apply speed
  pos = pos * speed - Math.floor(pos * speed)
  // Apply initial position
  pos += alternate ? initialPosition / 2 : initialPosition
  pos = pos > 1 ? pos - 1 : pos
  // Apply alternate (first 0.5 is forward, last 0.5 is backwards)
  if (alternate) {
    pos = pos > 0.5 ? (1 - pos) * 2 : pos * 2
  }
  // Apply range
  return min + pos * (max - min)
}
