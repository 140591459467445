import AffineTransform from './AffineTransform'
import GraphicsObject from './GraphicsObject'

/**
 * Represents a composite object, which is a group of other objects.
 */
export default class GraphicsObjectGroup extends GraphicsObject {
  readonly children: GraphicsObject[]

  constructor(elements: GraphicsObject[] = []) {
    super()
    this.children = elements
  }

  addChild(element: GraphicsObject): GraphicsObject {
    this.children.push(element)
    return this
  }

  addChildren(elements: GraphicsObject[]): GraphicsObject {
    elements.forEach((element) => this.children.push(element))
    return this
  }

  toString() {
    return `ElementGroup(${this.children.length})`
  }

  copy(transform?: AffineTransform): GraphicsObjectGroup {
    const copy = new GraphicsObjectGroup(this.children)
    copy.setTransform(transform ? this.transform.concatenate(transform) : this.transform)
    return copy
  }
}
