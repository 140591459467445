import Path from './Path'
import Point from './Point'

/**
 * Represents a rectangle a 2D space
 */
export default class Rect extends Path {
  readonly x: number
  readonly y: number
  readonly w: number
  readonly h: number

  constructor(x: number, y: number, w: number, h: number) {
    super([new Point(x, y), new Point(x + w, y), new Point(x + w, y + h), new Point(x, y + h)])
    this.x = x
    this.y = y
    this.w = w
    this.h = h
  }

  toString() {
    return `Rect(${this.x},${this.y},${this.w},${this.h})`
  }
}
